import React, { useState, useEffect } from 'react';
import {
  Button,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { Eye, EyeSlash, GoogleLogo, FacebookLogo } from 'phosphor-react';

import Messages, { MessagesTypes } from '../../utility/Messages';
import track from '../../../lib/track';
import validations, { composeValidators } from '../../../lib/validation';
import { normalizeText, normalizePhone } from '../../../lib/helpers';
import { signup } from '../../../actions/auth';
import { googleLogin, facebookLogin } from '../../../actions/oauth';
import TextField from '../../utility/TextField';
import { buttonStyles } from '../../../styles/base';
import { ToriiPlum } from '../../utility/Icons';
import LogoLoader from '../../utility/LogoLoader';
import EventEmitter from '../../../lib/eventEmitter';

export interface Props {
  classes: any;
  closeDialog: Function;
  dispatch: any;
  messages: MessagesTypes;
  signupDetails: any;
  signupPath: string;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
  entrancePath: string;
}

export const SaveAndContinueDialogContents: React.FC<Props> = ({
  classes,
  dispatch,
  messages,
  signupDetails,
  signupPath,
  utmCampaign,
  utmMedium,
  utmSource,
  utmTerm,
  entrancePath,
}) => {
  const { firstName, lastName } = signupDetails;
  const [showLoader, setLoader] = useState<boolean>(false);
  const [tracked, setTracked] = useState<any>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);

  React.useEffect(() => {
    EventEmitter.on('signupError', _handleSignupError);
    return () => {
      EventEmitter.removeListener('signupError', _handleSignupError);
    };
  }, []);

  const _handleSignupError = () => {
    setLoader(false);
  };

  const handleGoogle = () => {
    setLoader(true);
    dispatch(
      googleLogin(
        true,
        undefined,
        signupPath,
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        entrancePath,
        true,
      ),
    );
  };

  const handleFacebook = () => {
    setLoader(true);
    dispatch(
      facebookLogin(
        true,
        undefined,
        signupPath,
        utmCampaign,
        utmMedium,
        utmSource,
        utmTerm,
        entrancePath,
        true,
      ),
    );
  };

  const handleSubmit = (values: any) => {
    setLoader(true);
    const signupValues = {
      ...values,
      firstName,
      lastName,
      signupPath,
    };
    dispatch(signup(signupValues, undefined, true));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!tracked) {
      track.modalView('SaveAndContinue Dialog');
      setTracked({ tracked: true });
    }
  }, []);

  return (
    <div className="save-dialog-inner">
      <ToriiPlum />
      <span className="heading">
        Create an account to save <br className="no-tablet no-desktop" /> your
        progress
      </span>
      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        render={({ handleSubmit, valid }) => (
          <div className="sign-up-dialog-form-container">
            <div className="input-container">
              <InputLabel id="email-label" className="input-label">
                Email Address
              </InputLabel>
              <Field
                component={TextField}
                format={normalizeText}
                fullWidth
                name="email"
                type="email"
                placeholder="email@email.com"
                validate={composeValidators(
                  validations.required,
                  validations.email,
                )}
                InputProps={{
                  disableUnderline: true,
                }}
                className="input"
              />
            </div>
            <div className="input-container">
              <InputLabel id="phone-number-label" className="input-label">
                Phone Number
              </InputLabel>
              <Field
                autoComplete="off"
                component={TextField}
                format={normalizePhone}
                fullWidth
                name="phoneNumber"
                type="text"
                placeholder="123-456-789"
                validate={composeValidators(
                  validations.required,
                  validations.phoneNumber,
                )}
                className="input"
                InputProps={{ disableUnderline: true }}
              />
            </div>
            <div className="input-container">
              <InputLabel id="password-label" className="input-label">
                Password
              </InputLabel>
              <Field
                component={TextField}
                format={normalizeText}
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                validate={composeValidators(
                  validations.minLength8,
                  validations.required,
                )}
                className="input"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        disableRipple
                        style={{
                          backgroundColor: 'transparent',
                          marginRight: 8,
                        }}
                      >
                        {showPassword ? (
                          <EyeSlash
                            size={32}
                            color="#443461"
                            weight="regular"
                          />
                        ) : (
                          <Eye size={32} color="#443461" weight="regular" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <span className="terms-condition">
              By creating an account, you agree to the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.toriihomes.com/legal/terms"
                className="text-link"
              >
                terms of service
              </a>{' '}
              and our{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.toriihomes.com/legal/privacy"
                className="text-link"
              >
                privacy policy
              </a>
            </span>
            <Messages messages={messages} />
            <div className="form-submit">
              <Button
                className={classes.buttonSignupNavigation}
                onClick={handleSubmit}
                disableRipple
                disabled={!valid || showLoader}
              >
                {showLoader ? (
                  <LogoLoader imageProps={{ height: 50, width: 50 }} />
                ) : (
                  <span className="button-text">Sign up & Save Progress</span>
                )}
              </Button>
            </div>
            <div
              style={{
                margin: '10px 0',
                border: '1px solid #ABABAB',
                width: '100%',
              }}
            />
            <div className="form-submit">
              <Button
                onClick={handleGoogle}
                className={classes.buttonSocialAuth}
              >
                <GoogleLogo size={24} color="#2D2332" weight="regular" />
                <span className="auth-button-text">Sign up with Google</span>
              </Button>
            </div>
            <div className="form-submit">
              <Button
                onClick={handleFacebook}
                className={classes.buttonSocialAuth}
              >
                <FacebookLogo size={24} color="#2D2332" weight="fill" />
                <span className="auth-button-text"> Sign up with Facebook</span>
              </Button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.messages,
  signupDetails: state.auth.signupDetails,
  signupPath: state.auth.signupPath,
  utmCampaign: state.auth.utmCampaign,
  utmMedium: state.auth.utmMedium,
  utmSource: state.auth.utmSource,
  utmTerm: state.auth.utmTerm,
  entrancePath: state.auth.entrancePath,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(buttonStyles)(SaveAndContinueDialogContents));
