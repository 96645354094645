import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ResponsiveDialog from '../../utility/ResponsiveDialog';
import SaveAndContinueDialogContent from './SaveAndContinueDialogContent';
import { isMobile } from '../../../lib/getDeviceSize';
import * as types from '../../../actions/actionTypes';

export interface Props {
  dispatch: any;
  open: boolean;
  token: string;
}

export const SaveAndContinueDialog: React.FC<Props> = ({
  dispatch,
  open,
  token,
}) => {
  const handleClose = () => {
    dispatch({ type: types.CloseSaveAndContinueDialog });
  };

  useEffect(() => {
    if (open && token) {
      dispatch({
        type: types.CloseSaveAndContinueDialog,
      });
      dispatch({
        type: types.OpenSaveAndContinueSuccessDialog,
      });
    }
  }, [token]);

  return (
    <ResponsiveDialog
      mediumLargerPaper={isMobile() ? false : true}
      mediumLargerPaperMobile={isMobile() ? true : false}
      onClose={handleClose}
      open={open}
      className="save-dialog"
      closeIconStyles={{
        width: '32',
        height: '32',
      }}
      closeButtonColor="rgba(85, 85, 85, 1)"
      buttonClassName="save-dialog-closeButton"
    >
      <SaveAndContinueDialogContent closeDialog={handleClose} />
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.dialogs.saveAndContinueDialogOpen,
  token: state.auth.token,
});

export default connect(mapStateToProps)(SaveAndContinueDialog);
